import api from "store/api";
import { DateObject } from "react-multi-date-picker";

const trimStr = (str) => (str ? str.replace(/\s/g, "") : "");

export const getCarColorName = (color, locale = "zh-TW") => {
  return locale === "en"
    ? {
        white: "White",
        black: "Black",
        silver: "Midnight Silver",
        blue: "Blue",
        red: "Red",
        pink: "Pink!",
        quick_silver: "Quick Silver",
        sky_blue: "Light Blue",
        stealth_grey: "Stealth Grey",
      }[color] || ""
    : {
        white: "珍珠白",
        black: "純黑",
        silver: "夜銀",
        blue: "深藍",
        red: "亮紅",
        pink: "粉紅色",
        quick_silver: "閃電銀",
        sky_blue: "天空藍",
        stealth_grey: "低調夜幕灰",
      }[color] || "";
};

export const COLORS_URL = {
  white: "/images/cars/Paint_White.png",
  black: "/images/cars/Paint_Black.png",
  red: "/images/cars/Paint_Red.png",
  blue: "/images/cars/Paint_Blue.png",
  silver: "/images/cars/Paint_MidnightSilver.png",
  pink: "/images/cars/Paint_Pink.png",
  quick_silver: "/images/cars/Paint_Quick_Silver.png",
  sky_blue: "/images/cars/Paint_Sky_Blue.png",
  stealth_grey: "/images/cars/Paint_StealthGrey.png",
};

export const getInteriorColorName = (color, locale = "zh-TW") => {
  return locale === "en"
    ? {
        white: "white",
        black: "black",
        cream: "cream",
      }[color] || ""
    : {
        white: "白色",
        black: "黑色",
        cream: "米色",
      }[color] || "";
};

export const mapCarData = (car, locale = "zh-TW") => {
  const { lv1, lv2, img } = getCarGroupIds(car);
  return {
    ...car,
    group_lv1_id: lv1,
    group_lv2_id: lv2,
    image: img,
    color_i18n: getCarColorName(car?.color, locale),
    note: {
      ...car?.note,
      interior_i18n: getInteriorColorName(car?.note?.interior, locale),
    },
    displayPrice: {
      price: Math.round((car.price / 1.05) * 0.8).toLocaleString(),
      holiday_price: Math.round(car.holiday_price / 1.05).toLocaleString(),
      origin_price: Math.round(car.origin_price).toLocaleString(),
      hour_price: Math.round((car.price / 1.05 / 24) * 0.9),
      hour_holiday_price: Math.round((car.holiday_price / 1.05 / 24) * 0.9),
      hour_origin_price: Math.round(car.origin_price).toLocaleString(),
    },
  };
};

// model name
export const reduceCarsToOneLevel = (cars, locale = "zh-TW") => {
  const mapInfoCars = cars
    .map((c) => {
      return mapCarData(c, locale);
    })
    .sort(
      (a, b) =>
        a.origin_price - b.origin_price ||
        (a.color === "white" || a.color === "pink" ? -1 : 1)
    );

  const levelObj = mapInfoCars.reduce((acc, cur) => {
    const modelSeriesName = trimStr(cur.model_name);
    const modelName = modelSeriesName.split("(")[0];
    if (acc[modelName]) {
      if (acc[modelName][cur.group_lv1_id]) {
        if (acc[modelName][cur.group_lv1_id]["cars"]) {
          if (acc[modelName][cur.group_lv1_id]["cars"][cur.color]) {
            acc[modelName][cur.group_lv1_id]["cars"][cur.color].push(cur);
          } else {
            acc[modelName][cur.group_lv1_id]["cars"][cur.color] = [cur];
          }
        } else {
          acc[modelName][cur.group_lv1_id]["cars"][cur.color] = [cur];
        }
      } else {
        acc[modelName][cur.group_lv1_id] = {
          ...cur,
          cars: {
            [cur.color]: [cur],
          },
        };
      }
    } else {
      acc[modelName] = {
        [cur.group_lv1_id]: {
          ...cur,
          cars: {
            [cur.color]: [cur],
          },
        },
      };
    }
    return acc;
  }, {});

  return levelObj;
};

// model name + version
export const reduceCarsToTwoLevel = (cars, locale = "zh-TW") => {
  const mapInfoCars = cars.map((c) => {
    return mapCarData(c, locale);
  });

  const levelObj = mapInfoCars.reduce((acc, cur) => {
    const modelName = trimStr(cur.model_name);
    if (acc[modelName]) {
      if (acc[modelName][cur.group_lv1_id]) {
        if (acc[modelName][cur.group_lv1_id]["cars"]) {
          if (acc[modelName][cur.group_lv1_id]["cars"][cur.color]) {
            acc[modelName][cur.group_lv1_id]["cars"][cur.color].push(cur);
          } else {
            acc[modelName][cur.group_lv1_id]["cars"][cur.color] = [cur];
          }
        } else {
          acc[modelName][cur.group_lv1_id]["cars"][cur.color] = [cur];
        }
      } else {
        acc[modelName][cur.group_lv1_id] = {
          ...cur,
          cars: {
            [cur.color]: [cur],
          },
        };
      }
    } else {
      acc[modelName] = {
        [cur.group_lv1_id]: {
          ...cur,
          cars: {
            [cur.color]: [cur],
          },
        },
      };
    }
    return acc;
  }, {});

  return levelObj;
};

// model name + version + color
export const reduceCarsToOneLevelAddColor = (cars, locale = "zh-TW") => {
  const mapInfoCars = cars.map((c) => {
    return mapCarData(c, locale);
  });

  const levelObj = mapInfoCars.reduce((acc, cur) => {
    const modelName = trimStr(cur.group_lv2_id);
    if (acc[modelName]) {
      if (acc[modelName][cur.group_lv1_id]) {
        if (acc[modelName][cur.group_lv1_id]["cars"]) {
          if (acc[modelName][cur.group_lv1_id]["cars"][cur.color]) {
            acc[modelName][cur.group_lv1_id]["cars"][cur.color].push(cur);
          } else {
            acc[modelName][cur.group_lv1_id]["cars"][cur.color] = [cur];
          }
        } else {
          acc[modelName][cur.group_lv1_id]["cars"][cur.color] = [cur];
        }
      } else {
        acc[modelName][cur.group_lv1_id] = {
          ...cur,
          cars: {
            [cur.color]: [cur],
          },
        };
      }
    } else {
      acc[modelName] = {
        [cur.group_lv1_id]: {
          ...cur,
          cars: {
            [cur.color]: [cur],
          },
        },
      };
    }
    return acc;
  }, {});

  return levelObj;
};

// model name + version
export const reduceCarsToOneLevelAddVersion = (cars, locale = "zh-TW") => {
  const mapInfoCars = cars.map((c) => {
    return mapCarData(c, locale);
  });

  let resultMap = new Map();

  mapInfoCars.forEach((car) => {
    const modelName = trimStr(car.group_lv1_id);
    if (resultMap.has(modelName)) {
      resultMap.get(modelName).push(car);
    } else {
      resultMap.set(modelName, [car]);
    }
  });

  // return Object.fromEntries(resultMap);

  const mapList = Object.fromEntries(resultMap);
  let result = {};
  Object.keys(mapList).forEach((version) => {
    const group = mapList[version];

    // group 裡的 items 的 color 依照 white, black, 其他排序
    group.sort((a, b) => {
      // 定義排序的順序
      const order = ["white", "black"];
      const indexA = order.indexOf(a.color);
      const indexB = order.indexOf(b.color);

      // 比較邏輯
      if (indexA !== -1 && indexB !== -1) {
        // 兩者都在順序陣列中
        return indexA - indexB;
      } else if (indexA !== -1) {
        // 只有a在順序陣列中
        return -1;
      } else if (indexB !== -1) {
        // 只有b在順序陣列中
        return 1;
      } else {
        // 兩者都不在順序陣列中，按原來的顏色字典順序排序
        return a.color.localeCompare(b.color);
      }
    });

    result[version] = group;
  });

  return result;

  // const levelObj = mapInfoCars.reduce((acc, cur) => {
  //   const modelName = trimStr(cur.group_lv1_id);
  //   if (acc[modelName]) {
  //     acc[modelName].push(cur);
  //   } else {
  //     acc[modelName] = [
  //       {
  //         ...cur,
  //         cars: [cur],
  //       },
  //     ];
  //   }
  //   return acc;
  // }, {});

  // console.log("car_lib_reduceCarsToOneLevelAddVersion", levelObj);
  // return levelObj;
};

// Group Same Car
// Tesla-Model3-LongRange-white-fsd
// Tesla-Model3--white-
export const getCarGroupIds = (data) => {
  const brand = trimStr(data?.brand || "Tesla");
  let model = trimStr(data?.model_name || data?.model);
  const version = trimStr(data?.version);
  const color = trimStr(data?.color);

  let img = "";
  switch (model) {
    case "Model3":
      img = `/images/cars/${brand}-${model.replace(
        /\(.*\)/g,
        ""
      )}-${color}.png`;
      if (version.includes("LongRange")) {
        img = `/images/cars/${brand}-${model.replace(
          /\(.*\)/g,
          ""
        )}-LR-${color}.png`;
      }
      if (version.includes("煥新版")) {
        img = `/images/cars/${brand}-${model.replace(
          /\(.*\)/g,
          ""
        )}-HL-${color}.png`;
      }
      break;
    case "ModelY":
      img = `/images/cars/${brand}-${model.replace(
        /\(.*\)/g,
        ""
      )}-LR-${color}.png`;
      if (version.includes("Performance")) {
        img = `/images/cars/${brand}-${model.replace(
          /\(.*\)/g,
          ""
        )}-P-${color}.png`;
      }
      break;
    case "ModelS":
      img = `/images/cars/${brand}-${model.replace(
        /\(.*\)/g,
        ""
      )}-${color}.png`;
      break;
    case "ModelX":
      img = `/images/cars/${brand}-${model.replace(
        /\(.*\)/g,
        ""
      )}-${color}.png`;
      break;

    case "IX1":
      img = `/images/cars/${brand}-${model.replace(
        /\(.*\)/g,
        ""
      )}-${color}.png`;
      break;
    case "IONIQ6":
      img = `/images/cars/${brand}-${model.replace(
        /\(.*\)/g,
        ""
      )}-${color}.png`;
      break;

    default:
      img = `/images/cars/${brand}-${model.replace(
        /\(.*\)/g,
        ""
      )}-${color}.png`;
      break;
  }

  return {
    lv1: `${brand}-${model}-${version}`,
    lv2: `${brand}-${model}-${version}-${color}`,
    img,
  };
};

export const carVersionI18n = (version, t) => {
  if (version === "後驅") {
    return t("Car_version_後驅");
  } else if (version === "煥新版") {
    return t("Car_version_煥新版");
  }
  return version;
};

export const getTheCarId = (car_ids) => {
  return car_ids[Math.floor(Math.random() * car_ids.length)];
};

export const calcAvgAmount = ({
  selectedDays,
  selectedStartClock,
  selectedEndClock,
  car_id,
}) => {
  const st = new DateObject(
    selectedDays[0].format("YYYY/MM/DD") + " " + selectedStartClock
  );
  const et = new DateObject(
    selectedDays[1].format("YYYY/MM/DD") + " " + selectedEndClock
  );
  const data = {
    start_time: st.format("YYYY-MM-DD HH:mm"),
    end_time: et.format("YYYY-MM-DD HH:mm"),
    car_id,
  };

  return api.getAmount(data).then((resp) => {
    const noTaxTotal = Math.ceil(resp.data.price / 1.05);
    const diffTime = et.valueOf() / 1000 - st.valueOf() / 1000;
    const avgAmount = Math.ceil((noTaxTotal / (diffTime / 60 / 60)) * 24);
    return {
      totalAmount: resp.data.price,
      avgAmount,
    };
  });
};
